/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Center,
  Box,
  Text,
  Button,
  Textarea,
  Loader,
  Group,
  Tooltip,
  Grid,
  createStyles,
  Card,
  Avatar,
  Tabs,
  Progress,
} from '@mantine/core';
import { recordTypes, roleInitialNoteMap } from '../constants';
import { useMedplum } from '@medplum/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { getConfig } from '../config';
import {
  getSessionDetails,
  generateSummary,
  updateNote,
  downloadSummaryDetails,
  updateTranscript,
  saveTranscripts,
  completeUnknownPatientSession,
  deleteSessionAndNotes,
  getPractitionerRole,
  getTemplateListAccordingToRole,
  checkSubscriptionExpired,
} from '../utils/util';
import { toast } from 'react-toastify';
import { IconCopy, IconDownload, IconInfoCircle, IconMail, IconMicrophone } from '@tabler/icons-react';
import ConfirmBox from './ConfirmBox';
import { useAppContext } from '../AppProvider';
import EmailBox from './EmailBox';
import TemplateList from './TemplateList';
import PatientStepper from './PatientStepper';
import axios from '../utils/axios';
import UnknownSessionConfirmBox from '../patients/ConfirmBox';

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.md,
  },
}));
export const SessionDetails = () => {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const location = useLocation();
  const { compositionId, generatedNote } = location?.state || {};
  const navigate = useNavigate();
  const appointmentId = useParams().id;
  const [isStartRecordeing, setStartRecordeing] = useState(false);
  const [audioState, setAudioState] = useState(recordTypes.NOTSTARTED);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;
  const [transcripts, setTranscripts] = useState<string[]>(["Hello Doctor, I'm not feeling well today!"]);
  const [summary, setSummary] = useState<any>(null);
  const [isUpdateTranscripts, setIsUpdateTranscripts] = useState(false);
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [isSummaryUpdated, setIsSummaryUpdated] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [audioUpdate, setAudioUpdate] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [openEmailBox, setOpenEmailBox] = useState(false);
  const {
    setIsSessionOn,
    isConfirmOpen,
    setIsConfirmOpen,
    redirectionUrl,
    setRedirectionUrl,
    setIsSummaryDetailsUpdated,
    isOldPatientId,
    setUnknownSessionsRefresh
  } = useAppContext();
  const [trascriptDocReferenceId, setTrascriptDocReferenceId] = useState('');
  // const [soapSummary, setSoapSummary] = useState('');
  const [summaryNote, setSummaryNotes] = useState('');
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [noteId, setNoteId] = useState('');
  const [isGeneratedTranscript, setIsGeneratedTranscript] = useState<boolean>(false);
  const [isConsentBox, setIsConsentBox] = useState<boolean>(false);
  const [consentBoxEvent, setConsentBoxEvent] = useState<string>('');
  const [isCheckLoader, setIsCheckLoader] = useState<boolean>(false);
  const [isCheckSession, setIsCheckSession] = useState(false);
  const [isCheckActionBtn, setIsCheckActionBtn] = useState(true);
  const [jsxRendered, setJsxRendered] = useState(false);
  const [isCheckLoaderTranscript, setIsCheckLoaderTranscript] = useState(false);
  const [isSaveTranscripts, setIsSaveTranscripts] = useState(false);
  const [isTabsLoad, setIsTabLoad] = useState(true);
  const [isCheckNoteGenerated, setIsCheckNoteGenerated] = useState(false);
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isCheckNextBtnLoader, setIsCheckNextBtnLoader] = useState<boolean>(false);
  const [defaultPatientEmail, setdefaultPatientEmail] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);

  const [unknownSessionConfirm, setUnknownSessionConfirm] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);
  const [templatesList, setTemplatesList] = useState<any[]>([]);

  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];

  const [isNotesTabEnabled, setIsNotesTabEnabled] = useState(true);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);

  //tab close event to update the transcript
  const transcriptsRef = useRef(transcripts);
  useEffect(() => {
    transcriptsRef.current = transcripts;
  }, [transcripts]);

  useEffect(() => {
    if (isSaveTranscripts || isStartRecordeing) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSaveTranscripts, isStartRecordeing]);

  const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    const token = await medplum.getAccessToken();
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcriptsRef.current,
    };
    const response = await axios.put(`/intg/update-transcript`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    console.log(response)
  };

  const [newCompositionId, setnewCompositionId] = useState<string>('');

  useEffect(() => {
    const fetchCompositionId = async () => {
      try {
        const response = await getPractitionerRole(medplum, practitionerId);
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        const templateResponse = await getTemplateListAccordingToRole(medplum, role);

        if (templateResponse?.entry?.length >= 0) {
          const defaultTitle = 'Medication Management';
          const title = roleInitialNoteMap[role] || defaultTitle;
          const entries = templateResponse.entry;
          setTemplatesList(entries);
          const entry = entries.find((entry: any) => entry.resource.title === title);
          setnewCompositionId(entry?.resource.id);
        } else {
          toast.error('Error generating SOAP notes from transcript.');
          console.error('Role is not assigned to any template: ', role);
        }
      } catch (error) {
        console.error('Error fetching template list:', error);
      }
    };

    fetchCompositionId();
  }, []);

  useEffect(() => {
    if (templatesList.length > 0) {
      getSession();
    }
  }, [templatesList]);

  const getSession = (isNewTab:boolean = false, tabId:string = '') => {
    setIsTabLoad(false);
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        setdefaultPatientEmail(response?.patientEmail);
        setIsTabLoad(true);
        setIsCheckSession(true);
        setSessionDetails(response);
        setSummary(response?.soapSummary);
        if (generatedNote) {
          setSummaryNotes(generatedNote);
        } else {
          setSummaryNotes(response?.soapSummary[0]?.notes);
        }
        const compositionNames = [] as Array<string>;
        for (let template of templatesList) {
          const code = template.resource?.type?.coding?.[0]?.code || '';
          if (code === 'visit-type') {
            compositionNames.push(template?.resource.title!);
          }
        }

        const visitType = response?.soapSummary?.filter((item: any) =>
          compositionNames.includes(item.compositionName)
        );
        if (!visitType || visitType.length === 0) {
          const entry = templatesList.find((entry: any) => entry.resource.title === compositionNames[0]);
          visitType.push(entry);
        }
        if (compositionId) {
          setSelectedButton(compositionId);
        }

        if (isNewTab === true && !compositionId) {
          console.log(isNewTab);
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === tabId);
          setNoteId(noteId.noteId || tabId);
          setSummaryNotes(noteId?.notes);
        }
        else {
          setSelectedButton(visitType[0]?.compositionId);
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === compositionId);
          setNoteId(noteId?.noteId || response?.soapSummary[0]?.noteId);
        }
        
        setTranscripts(response?.transcriptData?.data?.transcripts);
        setTrascriptDocReferenceId(response?.transcriptData.transcriptDocId);
        if (response?.soapSummary?.length === 0 && response?.patientnName !== "unknown") {
          //toast.info('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
          setIsNotesTabEnabled(false);
          setIsProgressBarVisible(true);
          setProgressPercentage(0);
          const progressInterval = setInterval(() => {
            setProgressPercentage(prev => {
              if (prev < 90) {
                return prev + 10;
              } else {
                clearInterval(progressInterval);
                return prev;
              }
            });
          }, 1000);

          const checkNotesInterval = setInterval(() => {
            getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
              if (updatedResponse?.soapSummary?.length > 0) {
                // Notes have been generated
                setSummary(updatedResponse.soapSummary);
                setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
                setIsNotesTabEnabled(true);
                setProgressPercentage(100);
                getSession();

                // Hide progress bar after 5 seconds
                setTimeout(() => {
                  setIsProgressBarVisible(false);
                  clearInterval(checkNotesInterval);
                }, 5000);
              }
            });
          }, 5000);

        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const startTranscription = async () => {
    //added to check login session is expired or not
    try {
      await checkSubscriptionExpired(medplum)
    } catch (error) {
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription();
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }
    setIsSessionOn(true);
    const socket = io(transcribeUrl, { transports: ['polling'] });
    setSocket(socket);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioState(recordTypes.STARTED);
        console.log(audioState);
        setStartRecordeing(true);
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        socket.on('can-open-mic', () => {
          console.log('Opening mic...');
          if (mediaRecorder && mediaRecorder.state === 'inactive') {
            mediaRecorder.start(2000);
          }
        });
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            socket.emit('microphone-stream', e.data);
          }
        };
        socket.on('close-mic', () => {
          console.log('Closing mic');
          mediaRecorder.stop();
          socket.disconnect();
        });
        socket.on('transcript-result', (socketId, jsonFromServer) => {
          const result = jsonFromServer;
          if (result) {
            updateTranscripts(result);
          }
        });
        socket.on('disconnect', () => {
          console.log('Disconnected from server');
          mediaRecorder.stop();
          socket.disconnect();
        });
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const updateTranscripts = (transcript: string) => {
    setTranscripts((prevTranscripts) => {
      if (!Array.isArray(prevTranscripts)) {
        prevTranscripts = [];
      }
      return [...prevTranscripts, transcript];
    });
    setAudioUpdate(true);
  };

  const stopTranscription = () => {
    setAudioState(recordTypes.FINISHED);
    setStartRecordeing(false);
    setIsSessionOn(false);
    socket?.disconnect();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setStartRecordeing(false);

      // Access the stream and stop all tracks to disconnect the microphone
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });

      if (transcripts?.length) {
        setIsUpdateTranscripts(true);
        setIsSummaryDetailsUpdated(true);
        setIsSaveTranscripts(true);
      }
    }
  };

  const getSummary = (data?: any) => {
    const payload = {
      compositionId: [data?.id],
      trascriptDocReferenceId: trascriptDocReferenceId,
      patientId: sessionDetails?.patientId,
      practitionerId: sessionDetails?.practitionerId,
      appointmentId: sessionDetails?.appointmentId,
      encounterId: sessionDetails?.encounterId,
      transcript: transcripts,
    };
    setSelectedButton(data?.resource?.id);
    setIsLoading(true);
    toast.success('Notes will be generated in the background.');
    generateSummary(medplum, payload)
      .then(() => {
        toast.success('Notes generated successfully.', { autoClose: 2000 });
        setIsLoading(false);
        setIsUpdateTranscripts(false);
        setSelectedButton(data?.id);
        getSession(true, data.id);
        // setSoapSummary(response.data?.soapSummary?.notes);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  };

  const updateSummary = () => {
    const payload = {
      noteId: noteId,
      updatedNote: summaryNote,
    };

    setIsLoading(true);
    updateNote(medplum, payload)
      .then((reasponse) => {
        setIsLoading(false);
        toast.success(reasponse.message);
        setIsSummaryUpdated(false);
        setIsSummaryDetailsUpdated(false);
      })
      .catch((error: Error) => {
        setIsLoading(false);
        console.error('Error on updating Note:', error);
        toast.error('Failed to update the Note.');
      });
  };

  useEffect(() => {
    if (jsxRendered && Array.isArray(transcripts)) {
      const divElement = document.getElementById(`transcripts_data`);
      if (divElement) {
        divElement.innerHTML = '';
        transcripts.forEach((item: string) => {
          const newDiv = document.createElement('div');
          newDiv.innerText = item;
          newDiv.className = `transcripts_item`;
          divElement.appendChild(newDiv);
        });
        setAudioUpdate(false);
      }
    }
  }, [audioUpdate, jsxRendered]);


  const handleDivInput = (event: React.FormEvent<HTMLDivElement>) => {
    const text = event.currentTarget.innerText || event.currentTarget.textContent;
    const contentArray = text?.split('\n') || [];
    setIsUpdateTranscripts(true);
    setIsSaveTranscripts(true);
    setIsSummaryDetailsUpdated(true);
    setTranscripts(contentArray);
  };

  const downloadSummary = () => {
    setIsDownloading(true);
    if (noteId) {
      downloadSummaryDetails(medplum, noteId)
        .then((response: any) => {
          if (response.data) {
            const filename = response?.headers['content-disposition']?.split('filename=')?.[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}`;
            a.click();
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
            toast.success('Notes Downloaded Successfully.');
          }
        })
        .catch((error: any) => {
          setIsDownloading(false);
          console.error('Error :', error);
          toast.error('Download failed.');
        });
    }
  };

  const onConfirmExit = async () => {
    stopTranscription();
    setTranscripts([]);
    setIsSummaryDetailsUpdated(false);

    if (redirectionUrl) {
      if (redirectionUrl === '/signin') {
        await medplum.signOut();
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      } else {
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      }
    } else {
      setIsConfirmOpen(false);
      navigate('/session');
      setRedirectionUrl(undefined);
    }
  };

  const copyDetails = (summary: string) => () => {
    setIsCopied(true);
    navigator.clipboard
      .writeText(summary?.replace(/<\/?b>/g, ''))
      .then(() => {
        console.log('Copied to clipboard');
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error('Error on copying:', error);
        setIsCopied(false);
      });
  };

  const handleClickTemplate = (data: any) => {
    setSummaryNotes(data.notes);
    setSelectedButton(data.compositionId);
    setNoteId(data.noteId);
  };

  const updateGeneratedTranscript = () => {
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcripts,
      appointmentId: sessionDetails?.appointmentId,
    };

    setIsCheckLoader(true);
    setIsGeneratedTranscript(false)
    setIsCheckNoteGenerated(true);
    updateTranscript(medplum, payload)
      .then((response: any) => {
        setIsCheckLoader(false);
        setIsUpdateTranscripts(false);
        setIsConfirmOpen(false);
        setIsSessionOn(false);
        setIsSaveTranscripts(false);
        setIsSummaryDetailsUpdated(false);
        setIsCheckNoteGenerated(false);
        getSession();
        toast.success(response.message, { autoClose: 2000 });
      })
      .catch((error: any) => {
        toast.error(error);
        setIsUpdateTranscripts(false);
        setIsGeneratedTranscript(false);
      });
  };

  const handleClickTabs = (value: string) => {
    setSelectedButton(null);
    setIsCheckActionBtn(value === 'notedetails');

    if (isCheckNoteGenerated && value === 'notedetails' && sessionDetails?.patientnName !== "unknown") {
      //toast.success('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
      setIsNotesTabEnabled(false);
      setIsProgressBarVisible(true);
      setProgressPercentage(0);
      const progressInterval = setInterval(() => {
        setProgressPercentage(prev => {
          if (prev < 90) {
            return prev + 10;
          } else {
            clearInterval(progressInterval);
            return prev;
          }
        });
      }, 1000);
      const checkNotesInterval = setInterval(() => {
        getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
          if (updatedResponse?.soapSummary?.length > 0) {
            // Notes have been generated
            setSummary(updatedResponse.soapSummary);
            setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
            setIsNotesTabEnabled(true);
            setProgressPercentage(100);

            // Hide progress bar after 5 seconds
            setTimeout(() => {
              setIsProgressBarVisible(false);
              clearInterval(checkNotesInterval);
            }, 5000);
          }
        });
      }, 5000);

    }
  };

  const parseHTMLToPlainText = (html: string) => {
    return html?.replace(/<\/?b>/g, '');
  }

  const saveTranscript = () => {
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcripts,
    };
    setIsCheckLoaderTranscript(true);
    saveTranscripts(medplum, payload).then((response) => {
      setIsSaveTranscripts(false);
      setIsCheckLoaderTranscript(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
      toast.success(response.message, { autoClose: 2000 });
    }).catch((error) => {
      console.log(error);
      setIsCheckLoaderTranscript(false);
      setIsSaveTranscripts(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
    });
  }

  const handleEndSessionClick = () => {
    setIsAddPatient(true);
    setDisableButtons(true);
  };

  const onCancel = () => {
    setIsAddPatient(false);
  };

  const getSessionResp = (appointmentId: string) => {
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        getSummaryResp(response);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const getSummaryResp = (response?: any) => {
    const payload = {
      compositionId: [newCompositionId],
      trascriptDocReferenceId: response?.transcriptData?.transcriptDocId,
      patientId: response?.patientId,
      practitionerId: response?.practitionerId,
      appointmentId: response?.appointmentId,
      encounterId: response?.encounterId,
      transcript: transcripts,
    };
    generateSummary(medplum, payload)
      .then(() => {
        setIsCheckNextBtnLoader(false);
      })
      .catch((error: any) => {
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  }

  const endUnknownSession = async (patientData: any) => {
    setIsCheckNextBtnLoader(true);
    let navigateUrl: string | undefined;
    const { transcripts } = sessionDetails.transcriptData.data || { transcripts: [] };
    const payload = {
      appointmentId,
      patientName: patientData.patientDetails?.patientName,
      birthDate: patientData.patientDetails?.birthDate,
      transcripts,
      mentalHealthDiagnoses: patientData?.patientDetails?.mentalHealthDiagnoses,
      otherDiagnoses: patientData?.patientDetails?.otherDiagnoses,
      patientId: isOldPatientId,
    };

    try {
      const response = await completeUnknownPatientSession(medplum, payload);
      setIsAddPatient(false);
      setIsCheckNextBtnLoader(false);
      if (response) {
        navigateUrl = `/Appointment/${response.sessionId}/details`;
        getSessionResp(response.sessionId);
        toast.success('Session completed successfully.');
        setTimeout(() => {
          if (navigateUrl) {
            navigate(navigateUrl);
          } else {
            navigate('/session');
          }
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Notes will not be generated.', {
          onClose: () => navigate('/session')
        });
      }
    } catch (error) {
      toast.error('Error in completing the session. Please try again.');
      setIsCheckNextBtnLoader(false);
      setIsAddPatient(false);
      console.error('API Error:', error);
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    setDisableButtons(true);
    setUnknownSessionsRefresh(false);
    try {
      const path = window.location.pathname;
      const id = path.split('/').slice(-2, -1)[0];

      if (!id) {
        throw new Error('ID not found in URL');
      }
      const deleteResponse = deleteSessionAndNotes;
      deleteResponse(medplum, id)
        .then((response) => {
          if (response) {
            toast.success(`Session Deleted successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              setDisableButtons(false);
              navigate('/session');
            }, 2000);
            setUnknownSessionsRefresh(true);
            setIsDeletingLoader(false);
            setUnknownSessionConfirm(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting session:', error);
          toast.error(`Failed to delete the session`, {
            position: toast.POSITION.TOP_RIGHT
          });
          setDisableButtons(false);
          setIsDeletingLoader(false);
          setUnknownSessionConfirm(false);
        });
    } catch (error) {
      console.error(`Error deleting session:`, error);
      toast.error(`Failed to delete the session`, {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsDeletingLoader(false);
    }
  };


  return (
    <>
      <Box px="lg" pb="sm">
        <Grid>
          <Grid.Col span={12} md={12} mt={8}>
            <Card
              shadow="sm"
              sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
            >
              <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                <Grid>
                  <Grid.Col lg={1} className="avt-section">
                    <Avatar
                      src=""
                      radius="xl"
                      size="md"
                      sx={{ display: 'block', margin: 'auto' }}
                    />
                  </Grid.Col>
                  <Grid.Col lg={11} className="profile-section">
                    <Grid>
                      <Grid.Col lg={3} pl={40}>
                        <Text component="h6" className="label">
                          Patient Name
                        </Text>
                        <Text component="span">{sessionDetails?.patientnName || '-'}</Text>
                      </Grid.Col>
                      <Grid.Col lg={4} pl={40}>
                        <Text component="h6" className="label">
                          Appointment Date & Time
                        </Text>
                        <Text component="span">
                          {sessionDetails?.appointmentDate
                            ? sessionDetails?.appointmentDate
                            : '-'}
                        </Text>
                      </Grid.Col>
                      <Grid.Col lg={4} pl={40}>
                        <Text component="h6" className="label">
                          No. of Notes Generated
                        </Text>
                        <Text component="span">{summary?.length || '-'}</Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box px="lg" pb="sm" mt={20}>
        <Grid>
          {isProgressBarVisible && (
            <Grid.Col md={2} mb={12}>
              <Text>Generating Note</Text>
              <Progress
                size="xl"
                label={`${progressPercentage}%`}
                value={progressPercentage}
                color={progressPercentage === 100 ? 'green' : 'blue'}
              />
            </Grid.Col>
          )}
        </Grid>

        {sessionDetails?.patientnName && (
          <Tabs defaultValue={sessionDetails?.patientnName === "unknown" ? "transcript" : "notedetails"}>
            <Center w="100%" style={{ justifyContent: 'space-between' }}>
              <Tabs.List sx={{ borderBottom: 'none' }}>
                {sessionDetails?.patientnName !== "unknown" && (
                  <Tabs.Tab className='customTooltip' value="notedetails" disabled={!isNotesTabEnabled} fw={600} onClick={() => handleClickTabs('notedetails')}>Notes
                    <Tooltip
                      label="You can edit the notes directly without changing the transcript. If you want to regenerate the notes, update the transcript first."
                      withArrow
                      position="right"
                      sx={{ fontSize: '12px', fontWeight: 'normal' }}
                    >
                      <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                        <IconInfoCircle color='#444444d9' size={16} />
                      </span>
                    </Tooltip>
                  </Tabs.Tab>
                )}
                <Tabs.Tab className='customTooltip' value="transcript" fw={600} onClick={() => handleClickTabs('transcript')}>Transcript
                  <Tooltip
                    label=" You can edit or update the transcript, and once the recording is done, you can either save the transcript or update it to regenerate the note."
                    withArrow
                    position="right"
                    className='customTooltipStyle'
                    sx={{ fontSize: '12px' }}
                  >
                    <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                      <IconInfoCircle color='#444444d9' size={16} />
                    </span>
                  </Tooltip>
                </Tabs.Tab>
              </Tabs.List>
            </Center>
            {isCheckSession && (
              <Tabs.Panel value="transcript">
                <div ref={(ref) => ref && setJsxRendered(true)}>
                  <Grid>
                    <Grid.Col span={12} md={12} mt={20}>
                      <Card
                        shadow="sm"
                        sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
                      >
                        <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                          <Grid mt={10} mb={6} sx={{ display: 'block' }}>
                            <Text className="f-dark" fw={700} ml={9} mb={6} sx={{ fontSize: '18px' }}>
                              Transcript
                            </Text>
                            <Box className="soap-field" ml={10} style={{ minHeight: '400px' }}>
                              <div
                                className="text-area"
                                id="transcripts_data"
                                contentEditable={!isloading}
                                onInput={handleDivInput}
                              ></div>
                            </Box>
                          </Grid>
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  </Grid>
                  <Center w="100%" mt="lg" style={{ justifyContent: 'flex-end' }}>
                    {sessionDetails?.patientnName === 'unknown' && (
                      <Tooltip
                        label="End unassigned session and assign them to a patient."
                        withArrow
                        position="top"
                        sx={{ fontSize: '12px', maxWidth: '170px', whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'left' }}
                      >
                        <Button
                          className="btn-primary"
                          mr="lg"
                          onClick={handleEndSessionClick}
                          disabled={disableButtons || isStartRecordeing}
                        >
                          End Unknown Session
                        </Button>
                      </Tooltip>
                    )}
                    {!isStartRecordeing ? (
                      <Button
                        className="btn-primary"
                        mr="lg"
                        onClick={() => {
                          startTranscription();
                        }}
                        disabled={disableButtons}
                      >
                        Record More <IconMicrophone size={15} style={{ marginLeft: '5px' }} />
                      </Button>
                    ) : (
                      <Group>
                        <Button
                          className={`btn-danger ${audioState === recordTypes.STARTED && 'active-recording'}`}
                          h={20}
                          w={20}
                          p="2px"
                          style={{ background: 'red', borderRadius: '50%' }}
                        >
                          <IconMicrophone size={14} color="white" />
                        </Button>
                        <Button
                          className="btn-primary"
                          mr="lg"
                          onClick={() => {
                            stopTranscription();
                          }}
                        >
                          Stop
                        </Button>
                      </Group>
                    )}
                    <Button
                      className="btn-primary"
                      mr="lg"
                      onClick={saveTranscript}
                      disabled={!isSaveTranscripts || disableButtons}
                    >
                      {isCheckLoaderTranscript ? <Loader size={20} /> : 'Save Transcript'}
                    </Button>

                    {sessionDetails?.patientnName === 'unknown' && (
                      <Button
                        className="btn-danger"
                        mr="lg"
                        onClick={() => setUnknownSessionConfirm(true)}
                        disabled={disableButtons || isStartRecordeing}
                      >
                        Delete Session
                      </Button>
                    )}

                    {sessionDetails?.patientnName !== 'unknown' && (
                      <Button
                        className="btn-primary"
                        mr="lg"
                        onClick={updateGeneratedTranscript}
                        disabled={!isUpdateTranscripts}
                      >
                        {isCheckLoader ? (
                          <>
                            Updating Notes...
                            <Loader size={20} ml={10} />
                          </>
                        ) : (
                          'Save & Update Notes'
                        )}
                      </Button>
                    )}
                  </Center>
                </div>
              </Tabs.Panel>
            )}
            <Tabs.Panel value="notedetails">
              <Grid className="template-tabs" mt={5}>
                {isTabsLoad && (
                  <Tabs
                    defaultValue={selectedButton}
                    style={{ margin: '15px 0px', padding: 'calc(1rem / 2)' }}
                  >
                    <Tabs.List>
                      {summary?.map((data: any) => (
                        <Tooltip label={data.compositionName} position="top" key={data.compositionId}>
                          <Tabs.Tab
                            value={data.compositionId}
                            onClick={() => handleClickTemplate(data)}
                            sx={{ width: `${summary.length > 6 ? '100px' : 'auto'}`, overflow: 'hidden', textOverflow: 'clip', display: 'block !important', whiteSpace: 'nowrap' }}
                          >
                            {data.compositionName}
                          </Tabs.Tab>
                        </Tooltip>
                      ))}
                    </Tabs.List>
                  </Tabs>
                )}
              </Grid>
              <Grid>
                <Grid.Col span={12} md={9} mt={8} className='summary-text-area'>
                  <Card
                    shadow="sm"
                    sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px', height: '85vh' }}
                  >
                    <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                      <Textarea
                        value={parseHTMLToPlainText(summaryNote)}
                        w="100%"
                        minRows={18}
                        onChange={(e) => {
                          setSummaryNotes(e.target.value);
                          setIsSummaryUpdated(true);
                          setIsSummaryDetailsUpdated(true);
                        }}
                        className="summary-field"
                        disabled={isloading}
                        rightSection={isCheckActionBtn && (
                          <Group>
                            <Tooltip label="Copy" withArrow>
                              <Button
                                size="xs"
                                className={`btn-primary ${isCopied && 'btn-success'}`}
                                disabled={!summaryNote}
                                onClick={copyDetails(summaryNote)}
                              >
                                {isCopied ? 'Copied' : <IconCopy size={18} />}
                              </Button>
                            </Tooltip>
                            <Tooltip label="Email" withArrow>
                              <Button
                                size="xs"
                                className={`btn-primary`}
                                disabled={!summaryNote}
                                onClick={() => {
                                  setIsConsentBox(true);
                                  setConsentBoxEvent('email');
                                }}
                              >
                                <IconMail size={18} />
                              </Button>
                            </Tooltip>
                            <Tooltip label="Download" withArrow>
                              <Button
                                size="xs"
                                className={`btn-primary ${isDownloading && 'btn-success'}`}
                                disabled={!summaryNote}
                                onClick={downloadSummary}
                              >
                                {isDownloading ? 'Downloading...' : <IconDownload size={18} />}
                              </Button>
                            </Tooltip>
                          </Group>
                        )}

                      />
                      <Center w="100%" mt="lg" style={{ justifyContent: 'flex-end' }}>
                        <Button
                          className="btn-primary"
                          mr="lg"
                          disabled={!isSummaryUpdated}
                          onClick={() => {
                            updateSummary();
                          }}
                        >
                          {isloading ? <Loader size={20} /> : 'Save Notes'}
                        </Button>
                      </Center>
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col span={12} md={3} mt={8}>
                  {summary?.length > 0 && (
                    <TemplateList selectedTemplateNote={getSummary} summary={summary} />
                  )}
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        )}
      </Box>

      {isAddPatient && (
        <PatientStepper
          isOpen={isAddPatient}
          onClose={() => onCancel()}
          onConfirm={endUnknownSession}
          checkLoader={isCheckNextBtnLoader}
        />
      )}

      <UnknownSessionConfirmBox
        isOpen={unknownSessionConfirm}
        onClose={() => setUnknownSessionConfirm(false)}
        onConfirm={handleConfirmDelete}
        patient={''}
        confirm="Delete"
        cancel="Cancel"
        message={`Are you sure you want to delete this unknown session?`}
        additionalText=""
        isLoader={isDeletingLoader}
      />

      {isConfirmOpen && (
        <ConfirmBox
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={() => onConfirmExit()}
          content={
            'Please note that confirming this action will completely discard the unsaved changes. Would you like to proceed?'
          }
        />
      )}

      {isGeneratedTranscript && (
        <ConfirmBox
          isOpen={isGeneratedTranscript}
          onClose={() => setIsGeneratedTranscript(false)}
          onConfirm={() => updateGeneratedTranscript()}
          content={`Following notes will be updated ${summary
            ?.map((entry: { compositionName: any }) => entry.compositionName)
            .join(', ')}`}
        />
      )}

      {isConsentBox && (
        <ConfirmBox
          isOpen={isConsentBox}
          onClose={() => setIsConsentBox(false)}
          onConfirm={() => {
            if (consentBoxEvent === 'email') {
              setOpenEmailBox(true);
              setIsConsentBox(false);
            } else {
              downloadSummary();
              setIsConsentBox(false);
            }
          }}
          content={`Please note that you would need patient's consent to share Patient's PHI data externally.`}
        />
      )}

      {openEmailBox && (
        <EmailBox isOpen={openEmailBox} onClose={() => setOpenEmailBox(false)} documentReferenceId={noteId} defaultEmail={defaultPatientEmail} />
      )}
    </>
  );
};

