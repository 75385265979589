/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Center, Pagination, Table, Text, createStyles } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export function Sessions(): JSX.Element | null {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const patientID = useParams().id;
  const [appointmentResponse, setAppointmentResponse] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const navigate = useNavigate();

  const getAppointment = async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    try {
      const response = await axios.get(
        `intg/get-patient-sessions?patient=${patientID}&_offset=${offset}&identifier:not=unknown&_count=20&_sort=-_lastUpdated&_total=accurate`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data.entry;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    getAppointment()
      .then((response: any) => {
        setAppointmentResponse(response);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, [currentPage]);

  const paginate = (data: any) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    return data.slice(startIndex, endIndex);
  };

  const handleRowClick = (row: any) => {
    if (row.id) {
      navigate(`/Appointment/${row.id}/details`);
    }
  };

  return (
    <div className="resource-panel">
      <div className="resource-list">
        <div>
          <Table>
            <thead>
              <tr>
                <th className="table-heading">Session</th>
                <th className="table-heading">Date & Time</th>
                {medplum.isSuperAdmin() && <th className="table-heading">Tokens/Cost</th>}
              </tr>
            </thead>
            <tbody>
              {appointmentResponse.length > 0 ? (
                paginate(appointmentResponse)?.map((data: any, index: number) => (
                  <tr key={index} className={classes.tr} onClick={() => handleRowClick(data.resource)}>
                    <td>{data.resource?.description || ''}</td>
                    <td>{data.resource?.start ? data?.resource?.start : ''}</td>
                    {medplum.isSuperAdmin() && <td>
                      {data.resource?.numberOfTokens || ''}
                      {data.resource?.numberOfTokens && data.resource?.indicativeCost ? ' / ' : ''}
                      ${Number(data.resource?.indicativeCost).toFixed(5) || ''}
                    </td>}
                  </tr>
                ))
              ) : (
                <tr key={0} className={classes.tr}>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <Text>No Data</Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(appointmentResponse.length / perPage)}
              onChange={setCurrentPage}
            />
          </Center>
        </div>
      </div>
    </div>
  );
}
