/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, forwardRef } from 'react';
import { Grid, TextInput, Text, Button, MultiSelect, Select } from '@mantine/core';
import { ResourceType } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPatientDetails } from '../utils/util';
import icdCodes from './../seeds/icd-codes.json';
import otherIcdCodes from './../seeds/other-icd-codes.json';
import { ICDCodeProps, defaultRaceOptions } from '../constants';
import { cleanResource } from './utils';
import { normalizeErrorString } from '@medplum/core';
import { showNotification } from '@mantine/notifications';
import { DateInput } from '@mantine/dates';

const PatientEditPage = () => {
  const medplum = useMedplum();
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const navigate = useNavigate();
  const [diagnosesList, setDiagnosesList] = useState<any[]>([]);
  const [tempDiagnosesList, setTempDignosesList] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [otherDiagnosesList, setOtherDiagnosesList] = useState<any[]>([]);
  const [tempOtherDiagnosesList, setTempOtherDiagnosesList] = useState<any[]>([]);
  const [selectedOtherOptions, setSelectedOtherOptions] = useState<any[]>([]);
  const [otherSearchQuery, setOtherSearchQuery] = useState<string>('');
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedRace, setSelectedRace] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    mentalHealthDiagnoses: '',
    otherDiagnoses: '',
    race: '',
    mobile: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  });

  useEffect(() => {
    getPatientDetails(medplum, id)
      .then((response: any) => {
        setPatientDetails(response);
        setFormData((prevFormData: any) => {
          return {
            ...prevFormData,
            firstName: response?.name?.[0].given[0],
            lastName: response?.name?.[0].family,
            birthDate: response?.birthDate,
            gender: response?.gender,
            mobile:
              response?.telecom?.length > 0
                ? response?.telecom?.find((item: any) => item.system === 'phone').value
                : '',
            email:
              response?.telecom?.length > 0
                ? response?.telecom?.find((item: any) => item.system === 'email').value
                : '',
            line1: response?.address?.[0].line?.[0],
            line2: response.address?.[0].line?.[1],
            city: response.address?.[0].city,
            state: response.address?.[0].state,
            postalCode: response.address?.[0].postalCode,
            mentalHealthDiagnoses: extractLabels(
              response?.extension,
              '/intg/structure/extensions/mentalHealthDiagnoses'
            ),
            otherDiagnoses: extractLabels(response?.extension, '/intg/structure/extensions/otherDiagnoses'),
            race: response?.extension.find(
              (item: any) => item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race'
            )?.valueCodeableConcept?.coding?.[0]?.code,
          };
        });
        setSelectedRace(response?.extension.find(
          (item: any) => item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race'
        )?.valueCodeableConcept?.coding?.[0]?.display);
        setSelectedOtherOptions([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

        setSelectedOptions([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);

        setOtherDiagnosesList([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

        setDiagnosesList([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [medplum, id]);

  const extractLabels = (extensions: any[], url: string) => {
    const extension = extensions.find((ext) => ext.url === url);
    const valueString = extension ? JSON.parse(extension.valueString) : [];
    return valueString.map((diagnosis: { value: any }) => diagnosis.value).filter((value: any) => !!value);
  };

  const handleSearchChange = (query: string): any => {
    setSearchQuery(query);

    if (query.trim() !== '' && query?.length >= 3) {
      const filteredDiagnosesList = icdCodes.filter(
        (diagnoses) => diagnoses.label?.toLowerCase()?.includes(query?.toLowerCase())
      );

      const updatedListWithValues = filteredDiagnosesList.map((item: ICDCodeProps) => {
        if (!item?.value) {
          return { ...item, value: `${item.code} - ${item.label}` };
        }
        return item;
      });

      if (selectedOptions?.length) {
        setDiagnosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
        setTempDignosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
      } else {
        setDiagnosesList([...updatedListWithValues]);
        setTempDignosesList([...updatedListWithValues]);
      }
    }
  };

  const handleSelectChangeMentalHealth = (values: any[]): any => {
    const selectedItems: any = [];
    tempDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          selectedItems.push(item);
        }
      });
    });
    setSelectedOptions([...selectedItems]);
  };

  const handleOtherSearchChange = (query: string): any => {
    setOtherSearchQuery(query);

    if (query.trim() !== '' && query?.length >= 3) {
      const filteredOtherDiagnosesList = (otherIcdCodes as ICDCodeProps[]).filter(
        (diagnoses) => diagnoses.label?.toLowerCase()?.includes(query?.toLowerCase())
      );

      const updatedListWithValues = filteredOtherDiagnosesList.map((item: ICDCodeProps) => {
        if (!item?.value) {
          return { ...item, value: `${item.code} - ${item.label}` };
        }
        return item;
      });

      if (selectedOtherOptions?.length) {
        setOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
        setTempOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
      } else {
        setOtherDiagnosesList([...updatedListWithValues]);
        setTempOtherDiagnosesList([...updatedListWithValues]);
      }
    }
  };

  const handleOtherSelectChange = (values: any[]): any => {
    const selectedItems: any = [];
    tempOtherDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          selectedItems.push(item);
        }
      });
    });
    setSelectedOtherOptions([...selectedItems]);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const regex = /^[0-9()\-\s]*$/;

    if (name === 'mobile') {
      if (value === '' || regex.test(value)) {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const parseDate = (dateString: string): Date => {
    return new Date(Date.parse(dateString));
  };

  const formatDate = (date: Date): string => {
    return `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()}`;
  };

  const handleSubmit = () => {
    const phoneRegex = /^(?:\d{10}|\(\d{3}\)\d{3}-\d{4}|\(\d{3}\) \d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;

    if (formData.mobile && !phoneRegex.test(formData.mobile)) {
      showNotification({ color: 'red', message: 'Invalid phone number format' });
      return;
    }

    patientDetails.telecom = [
      {
        system: 'email',
        value: formData.email,
      },
      {
        system: 'phone',
        value: formData.mobile,
      },
    ];
    patientDetails.address = [
      {
        line: [formData?.line1 || '', formData?.line2 || ''],
        city: formData?.city || '',
        state: formData?.state || '',
        postalCode: formData?.postalCode || '',
      },
    ];
    patientDetails.name[0].given[0] = formData.firstName;
    patientDetails.name[0].family = formData.lastName;
    patientDetails.birthDate = formData.birthDate;
    patientDetails.gender = formData.gender;
    patientDetails?.extension?.forEach((item: any) => {
      if (item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race') {
        item.valueCodeableConcept = {
          coding: [
            {
              code: formData.race,
              display: selectedRace,
              system: 'http://hl7.org/fhir/us/core/CodeSystem/us-core-race',
            },
          ],
        };
      }
      if (item.url === '/intg/structure/extensions/mentalHealthDiagnoses') {
        item.valueString = JSON.stringify(selectedOptions);
      }
      if (item.url === '/intg/structure/extensions/otherDiagnoses') {
        item.valueString = JSON.stringify(selectedOtherOptions);
      }
    });
    medplum
    .updateResource(cleanResource(patientDetails))
    .then(() => {
      navigate(`/${resourceType}/${id}/details`);
      showNotification({ color: 'green', message: 'Success' });
    })
    .catch((err) => {
      showNotification({ color: 'red', message: normalizeErrorString(err) });
    });
  };
  const ItemComponent = forwardRef<HTMLDivElement, any>(({ label, code, ...others }: any, ref) => {
    return (
      <div ref={ref} {...others}>
        <Grid>
          <Grid.Col md={2} lg={2}>
            {code}
          </Grid.Col>
          <Grid.Col md={10} lg={10}>
            {label}
          </Grid.Col>
        </Grid>
      </div>
    );
  });
  return (
    <div>
      {dataLoaded && (
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (handleSubmit) {
              handleSubmit();
            }
          }}
        >
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Name</Text>
            </Grid.Col>
            {/* Name */}
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.firstName || ''}
                name="firstName"
                placeholder="First Name"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.lastName || ''}
                name="lastName"
                placeholder="Last Name"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Date of Birth</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <DateInput
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date.getTime())) {
                    handleInputChange({ target: { name: 'birthDate', value: formatDate(date) } });
                  } else {
                    handleInputChange({ target: { name: 'birthDate', value: '' } });
                  }
                }}
                maxDate={new Date()}
                value={formData.birthDate ? parseDate(formData.birthDate) : null}
                style={{ marginBottom: '20px' }}
                label="Date of Birth"
                placeholder="MM-DD-YYYY"
                valueFormat="MM-DD-YYYY"
              />
            </Grid.Col>
          </Grid>

          {/* MultiSelect for Mental Health Diagnosis */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Mental Health Diagnosis</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <MultiSelect
                multiple
                mb="md"
                data={diagnosesList}
                itemComponent={ItemComponent}
                placeholder="Search and select options"
                defaultValue={formData.mentalHealthDiagnoses}
                searchable
                searchValue={searchQuery}
                onSearchChange={handleSearchChange}
                onChange={handleSelectChangeMentalHealth}
              />
            </Grid.Col>
          </Grid>

          {/* MultiSelect for Other Diagnosis */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Other Diagnosis</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <MultiSelect
                multiple
                data={otherDiagnosesList}
                searchable
                placeholder="Search and select options"
                id="otherDiagnosis"
                name="otherDiagnoses"
                defaultValue={formData?.otherDiagnoses || []}
                searchValue={otherSearchQuery}
                onSearchChange={handleOtherSearchChange}
                onChange={handleOtherSelectChange}
              />
            </Grid.Col>
          </Grid>

          {/* Mobile */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Mobile</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <TextInput
                value={formData.mobile || ''}
                type="text"
                id="mobile"
                name="mobile"
                placeholder="Mobile Number"
                onChange={handleInputChange}
                maxLength={14}
              />      
            </Grid.Col>
          </Grid>

          {/* Email */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Email</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <TextInput
                value={formData.email || ''}
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          {/* Gender */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Gender</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <Select
                data={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'other', label: 'Other' },
                  { value: 'non-binary', label: 'Non-binary' },
                  { value: 'transgender-male', label: 'Transgender-Male' },
                  { value: 'transgender-female', label: 'Transgender-Female' },
                  { value: 'non-disclosure', label: 'Non-Disclosure' },
                ]}
                value={formData?.gender || ''}
                placeholder="Select"
                name="gender"
                onChange={(value) =>
                  setFormData((prevData: any) => ({
                    ...prevData,
                    gender: value,
                  }))
                }
              />
            </Grid.Col>
          </Grid>

          {/* Race dropdown */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Race</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <Select
                data={defaultRaceOptions}
                placeholder="Select"
                name="race"
                value={formData.race || ''}
                onChange={
                  (value) => {
                    const selectedOption = defaultRaceOptions.find((option) => option.value === value);
                    setSelectedRace(selectedOption?.label);
                    setFormData((prevData: any) => ({
                      ...prevData,
                      race: value,
                    }))
                  }
                }
              />
            </Grid.Col>
          </Grid>

          {/* Address */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Address</Text>
            </Grid.Col>

            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.line1 || ''}
                type="text"
                id="line1"
                name="line1"
                placeholder="Line 1"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.line2 || ''}
                type="text"
                id="line2"
                name="line2"
                placeholder="Line 2"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          {/* City, State, Postal Code */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}></Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.city || ''}
                type="text"
                id="city"
                name="city"
                placeholder="City"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.state || ''}
                type="text"
                id="state"
                name="state"
                placeholder="State"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}></Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.postalCode || ''}
                type="number"
                id="postalCode"
                name="postalCode"
                placeholder="Postal Code"
                onChange={handleInputChange}
                maxLength={6}
              />
            </Grid.Col>
          </Grid>

          {/* Buttons */}
          <Grid sx={{ justifyContent: 'flex-end' }}>
            <Grid.Col span={12} lg={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outline"
                type="button"
                onClick={() => navigate(`/${resourceType}/${id}/details`)}
                style={{ marginRight: '8px' }}
              >
                Cancel
              </Button>
              <Button type="submit" sx={{ marginTop: '0'}}>Update</Button>
            </Grid.Col>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default PatientEditPage;
