import React, { useState } from 'react'
import { Button, Modal, TextInput, Loader, Group, Radio, Textarea, } from '@mantine/core';

interface PractitionerDetails {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    withNPI?: boolean;
    reason?: string;
}

interface InvitePopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (details: PractitionerDetails) => void;
    header: string;
    submitButtonLabel: string;
    cancelButtonLabel: string;
    isLoading?: boolean;
}

export const practitionerMap: Record<string, string> = {
    "prescriber": "Psychiatry",
    "therapist": "Counselor",
    "psychological testing": "Psychological Testing"
};

const InvitePopup: React.FC<InvitePopupProps> = ({ isOpen, onClose, onSubmit, header, submitButtonLabel, cancelButtonLabel, isLoading}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('Prescriber'); 
    const [withNPI, setNpi] = useState(true);
    const [reason, setReason] = useState('');

    const getRoleLabel = (role: string) => {
        return practitionerMap[role.toLowerCase()] || role; 
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const practitionerDetails: PractitionerDetails = { firstName, lastName, email, role,  withNPI, reason: reason };
        onSubmit(practitionerDetails);
    };

    return (
        <Modal opened={isOpen} onClose={onClose} centered className='invitePopup'>
            <h2 className="inviteHeader">{header}</h2>
            <form onSubmit={handleFormSubmit}>
            <div className="name-inputs-row">
                <TextInput
                    p={10}
                    label="First Name"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    required
                />
                <TextInput
                    p={10}
                    label="Last Name"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    required
                />
                </div>
                <TextInput
                    p={10}
                    label="Email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    type="email"
                    required
                />
                <div className="padded-container">
                    <label className='user-role-label'>
                        Set User Role <span className='mantine-103svbs'>*</span>
                    </label>
                    <Group>
                        <Radio
                            className='InviteRadio'
                            value="Prescriber"
                            label={getRoleLabel('Prescriber')}
                            checked={role === 'Prescriber'}
                            onChange={() => setRole('Prescriber')}
                        />
                        <Radio
                            className='InviteRadio'
                            value="Therapist"
                            label={getRoleLabel('Therapist')}
                            checked={role === 'Therapist'}
                            onChange={() => setRole('Therapist')}
                        />
                    </Group>
                </div>
                {header == 'Invitation Details' && (
                    <div className="padded-container">
                        <label className='npi-label'>
                            Select Option <span className='mantine-103svbs'>*</span>
                        </label>
                        <Group>
                            <Radio
                                className="InviteRadio"
                                value="with-npi"
                                label="With NPI"
                                checked={withNPI === true}
                                onChange={() => setNpi(true)}
                            />
                            <Radio
                                className="InviteRadio"
                                value="without-npi"
                                label="Without NPI"
                                checked={withNPI === false}
                                onChange={() => setNpi(false)}
                            />
                        </Group>
                    </div>
                )}
                {header === 'Non-NPI user details' && (
                    <div className="padded-container">
                        <Textarea
                            label="Write reason for not having an NPI"
                            value={reason}
                            onChange={(e) => setReason(e.currentTarget.value)}
                            placeholder="Write reason for not having an NPI"
                            mb="md"
                            required
                        />
                    </div>
                )}
                <Group position="right" mt="md">
                    <Button onClick={onClose} className='cancelButton'>
                        {cancelButtonLabel}
                    </Button>
                    <Button type="submit" className='defaultBg'>
                        {submitButtonLabel} {isLoading && <Loader ml={10} size={20} color='white' />}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};

export default InvitePopup;